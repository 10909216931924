import React from "react"

import { Link } from "gatsby"
import Modal from "../modal/modal-finance-industry"

import "./hero.css"

const FinanceIndustryBanner = () => {
  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-financial-services-banner"
        // preserveStackingContext
        style={{ backgroundPosition: "top center", height: "700px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="hero-custom-quickbase">
                <h1 className="page-title font-weight-medium mt-0 mb-4">
                  Trade Busy Work for Value Creation with Financial Services
                  Automation
                </h1>
                <p className="mb-0" style={{ paddingBottom: "20px" }}>
                  We automate data management for financial institutions so they
                  can exceed customer expectations, navigate markets, reduce
                  risk, and stay compliant.
                </p>
                <Modal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinanceIndustryBanner
